import { Wrapped } from '@zg-rentals/util';

export interface Reporter {
  count?: (name: string, amount: number) => Promise<void>;
  gauges?: (name: string, amount: number) => Promise<void>;
  measurementWithGauges?: (name: string, amount: number) => Promise<void>;
  error?: (args: { error: Error }) => Promise<void>;
}

export function defineReporter(reporter: Reporter) {
  return reporter;
}

export interface Count {
  name: string;
  amount?: number;
}

export interface Measure {
  name: string;
  amount: number;
}

export class Monitor {
  constructor(protected readonly reporters: Array<Reporter> = []) {}

  count(...counts: Array<Count>) {
    return Wrapped.of(
      Promise.all(
        counts.flatMap(({ name, amount = 1 }) => this.reporters.map((reporter) => reporter.count?.(name, amount))),
      ).catch((error) => this.error({ error })),
    );
  }

  gauges(...measures: Array<Measure>) {
    return Wrapped.of(
      Promise.all(
        measures.flatMap(({ name, amount }) => this.reporters.map((reporter) => reporter.gauges?.(name, amount))),
      ).catch((error) => this.error({ error })),
    );
  }

  measurementWithGauges(...measures: Array<Measure>) {
    return Wrapped.of(
      Promise.all(
        measures.flatMap(({ name, amount }) =>
          this.reporters.map((reporter) => reporter.measurementWithGauges?.(name, amount)),
        ),
      ).catch((error) => this.error({ error })),
    );
  }

  error(arg: { error: Error } & Record<string, unknown>) {
    return Wrapped.of(Promise.all(this.reporters.map((reporter) => reporter.error?.(arg))));
  }
}
