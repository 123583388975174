export * from './BatchWriter';
export * from './PhaseTimer';
export * from './callerTool';
export * from './makeModuleInstance';
export * from './warningBanner';
export * from './withRetries';

export class Wrapped<T> {
  static of<T>(value: T) {
    return new this(value);
  }

  constructor(private readonly value: T) {}

  async unwrap() {
    return this.value;
  }
}
