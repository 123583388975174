// Lodash
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';

// Enums
import { COMMUTE_MODE_TYPES_JAVA_TO_REDUX, FILTER_ENUM } from 'app/shared/constants/FilterConstants';
import type {
  AdditionalOptions,
  AmenitiesFilter,
  AvailabilityFilter,
  BathroomFilter,
  BedroomFilter,
  CommuteFilter,
  CreatedWithinFilter,
  FilterState,
  LaundryFilter,
  NonUserFacing,
  PetsFilter,
  PriceRangeFilter,
  PropertyTypesFilter,
  RentalTypesFilter,
  RestrictionsFilter,
  SearchFilter,
  SqftFilter,
} from 'app/types/filter.type';

export const DEFAULT: FilterState = {
  search: {
    slug: 'apartments-for-rent',
    title: 'Apartments for Rent',
  },
  price: {
    min: null,
    max: null,
  },
  bedrooms: {
    anyOrStudio: true,
    oneOrMore: false,
    twoOrMore: false,
    threeOrMore: false,
    fourOrMore: false,
    isExact: false,
  },
  bathrooms: {
    any: true,
    oneOrMore: false,
    oneHalfOrMore: false,
    twoOrMore: false,
    threeOrMore: false,
    fourOrMore: false,
  },
  commute: {
    commuteLats: null,
    commuteLons: null,
    commuteMode: null,
    commuteTimeMode: null,
    commuteTime: null,
  },
  pets: {
    cats: false,
    dogs: false,
  },
  availability: {
    start: null,
    end: null,
    hideUnknownAvailabilityDate: null,
  },
  laundry: {
    inUnit: false,
    shared: false,
  },
  amenities: {
    cooling: false,
    heating: false,
    parking: false,
    gatedEntry: false,
    doorman: false,
    fitnessCenter: false,
    swimmingPool: false,
    dishwasher: false,
  },
  furnished: false,
  propertyTypes: {
    any: true,
    apartment: false,
    condo: false,
    duplex: false,
    house: false,
    townhouse: false,
  },
  sqft: {
    min: null,
    max: null,
  },
  createdWithin: {
    any: true,
    hour: false,
    day: false,
    week: false,
    month: false,
  },
  rentalTypes: {
    rental: true,
    room: true,
    sublet: true,
    corporate: true,
  },
  restrictions: {
    incomeRestricted: null,
    seniorHousing: null,
    studentHousing: null,
    militaryHousing: null,
    none: null,
  },
  keywords: '',
  additionalOpts: {
    requiresPhotos: false,
    requiresPrice: null,
    forRentByOwner: false,
    acceptsSection8: false,
    hasOffers: false,
    acceptingApplications: false,
  },
  orderBy: 'score',
  NON_USER_FACING: {
    feeds: null,
    dupeGrouping: null,
    visible: 'favorite,inquiry,new,note,notified,viewed',
  },
};

export const ALLOWED_CONSTANTS = {
  defaultListingTypes: ['rental', 'sublet', 'room', 'corporate'].sort(),
  defaultPropertyTypes: ['house', 'divided', 'condo', 'townhouse', 'medium', 'large', 'garden', 'land'].sort(),
};

type CommuteModeType = keyof typeof COMMUTE_MODE_TYPES_JAVA_TO_REDUX;
type OrderByKeyType = keyof typeof FILTER_ENUM.ORDER_BY;

// Type guard function
function isOrderByKeyType(key: string): key is OrderByKeyType {
  return key in FILTER_ENUM.ORDER_BY;
}

// Utility type to extract keys with object-type values
type ObjectFilterKeys = {
  [K in keyof FilterState]: FilterState[K] extends object ? K : never;
}[keyof FilterState];

const MODEL = {
  GENERAL: {
    standardTruthy: <T extends ObjectFilterKeys>(
      _val: string | Record<string, boolean> | null,
      type: T,
    ): FilterState[T] | null => {
      // Clone the default filter object for the specified type
      const filterObj: FilterState[T] = { ...DEFAULT[type] };

      if (!filterObj) {
        return null;
      }

      if (!_val) {
        return filterObj;
      }

      let newFilterObj: Partial<FilterState[T]> = {};

      if (isString(_val)) {
        const val = _val;
        newFilterObj = val
          .split(',')
          .sort()
          .filter((b) => Object.keys(filterObj).includes(b))
          .reduce(
            (obj, el) => {
              obj[el as keyof FilterState[T]] = true as FilterState[T][keyof FilterState[T]];
              return obj;
            },
            {} as Partial<FilterState[T]>,
          );
      } else if (isObject(_val)) {
        newFilterObj = _val as Partial<FilterState[T]>;
      }

      // Merge the default filter with the new filter object
      return { ...filterObj, ...newFilterObj };
    },
  },
  SPECIFIC: {
    orderBy: (str: string = DEFAULT.orderBy): string => {
      return isOrderByKeyType(str) ? str : DEFAULT.orderBy;
    },
    search: ({ slug = DEFAULT.search.slug, title = DEFAULT.search.title }) => {
      return {
        slug,
        title,
      };
    },
    price: ({ min = DEFAULT.price.min, max = DEFAULT.price.max }: PriceRangeFilter) => {
      const minNumber = min !== null && min !== undefined ? Number(min) : null;
      const maxNumber = max !== null && max !== undefined ? Number(max) : null;
      return {
        min: minNumber,
        max: maxNumber,
      };
    },
    sqft: ({
      min = DEFAULT.sqft.min,
      max = DEFAULT.sqft.max,
    }: {
      min?: number | null;
      max?: number | null;
    }): SqftFilter => {
      const minNumber = min !== null ? Number(min) : null;
      const maxNumber = max !== null ? Number(max) : null;

      return {
        min: minNumber,
        max: maxNumber,
      };
    },
    bedrooms: (str: string | null = null) => {
      if (!str) {
        return DEFAULT.bedrooms;
      }

      const bedroomsArray = str.split(',');
      const bedroomsKey = bedroomsArray[0];

      if (bedroomsKey in FILTER_ENUM.BEDROOMS) {
        const isExact = bedroomsArray.length === 1;

        return {
          anyOrStudio: bedroomsKey === '0', // Maps to Any or Studio
          oneOrMore: bedroomsKey === '1', // Maps to 1 or 1+
          twoOrMore: bedroomsKey === '2', // Maps to 2 or 2+
          threeOrMore: bedroomsKey === '3', // Maps to 3 or 3+
          fourOrMore: bedroomsKey === '4', // Maps to 4 or 4+
          isExact: isExact, // Dictates whether choices are "1" or "1+", "2" or "2+", etc
        };
      }

      return DEFAULT.bedrooms;
    },
    bathrooms: (str: string | null = null): BathroomFilter => {
      if (!str) {
        return DEFAULT.bathrooms;
      }
      const bathroomsArray = str.split(',');
      const bathroomKey = bathroomsArray[0];

      if (bathroomKey in FILTER_ENUM.BATHROOMS) {
        return {
          any: bathroomKey === '0', // Maps to Any
          oneOrMore: bathroomKey === '1', // Maps to 1+
          oneHalfOrMore: bathroomKey === '1.5', // Maps to 1.5+
          twoOrMore: bathroomKey === '2', // Maps to 2+
          threeOrMore: bathroomKey === '3', // Maps to 3+
          fourOrMore: bathroomKey === '4', // Maps to 4+
        };
      }

      return DEFAULT.bathrooms;
    },
    commute: ({
      lat = DEFAULT.commute.commuteLats,
      lon = DEFAULT.commute.commuteLons,
      mode = DEFAULT.commute.commuteMode,
      timeMode = DEFAULT.commute.commuteTimeMode,
      time = DEFAULT.commute.commuteTime,
    }: {
      mode?: string | null;
      timeMode?: string | null | undefined;
      time?: string | null | undefined;
      lat?: number | null;
      lon?: number | null;
    }): CommuteFilter => {
      const convertedLat = Number(lat);
      const convertedLon = Number(lon);
      const isAcceptedLat = !isNaN(convertedLat);
      const isAcceptedLon = !isNaN(convertedLon);
      const isAcceptedMode = !!mode && mode in FILTER_ENUM.COMMUTE;
      const isAcceptedTimeMode = !!timeMode && timeMode in FILTER_ENUM.COMMUTE;
      const isAcceptedTime = typeof time === 'string' ? time in FILTER_ENUM.COMMUTE : true; // Null is okay!
      const isValid = isAcceptedLat && isAcceptedLon && isAcceptedMode && isAcceptedTimeMode && isAcceptedTime;

      if (isValid) {
        return {
          commuteLats: convertedLat,
          commuteLons: convertedLon,
          commuteMode: mode ? COMMUTE_MODE_TYPES_JAVA_TO_REDUX[mode as CommuteModeType] : null,
          commuteTimeMode: timeMode ? COMMUTE_MODE_TYPES_JAVA_TO_REDUX[timeMode as CommuteModeType] : null,
          commuteTime: time,
        };
      }
      return DEFAULT.commute;
    },
    availability: ({
      start = DEFAULT.availability.start,
      end = DEFAULT.availability.end,
      hideUnknownAvailabilityDate = DEFAULT.availability.hideUnknownAvailabilityDate,
    }: {
      start?: string | null;
      end?: string | null;
      hideUnknownAvailabilityDate?: boolean | null;
    }): AvailabilityFilter => {
      const isValidStart = isString(start);
      const isValidEnd = isString(end);
      const isValidBool = hideUnknownAvailabilityDate === null || typeof hideUnknownAvailabilityDate === 'boolean';
      const isValid = isValidStart && isValidEnd && isValidBool;

      if (isValid) {
        return {
          start,
          end,
          hideUnknownAvailabilityDate,
        };
      }

      return DEFAULT.availability;
    },
    furnished: (str: string | boolean | null = null): boolean => {
      return str === 'true' || str === true;
    },
    propertyTypes: (propertyTypesStr: string | null = null): PropertyTypesFilter => {
      if (!propertyTypesStr) {
        return DEFAULT.propertyTypes;
      }

      const apartmentTypes = ['garden', 'large', 'medium'];

      const propertyTypesArray = propertyTypesStr.split(',');
      const apartmentType = apartmentTypes.some((type) => propertyTypesArray.includes(type));
      const condoType = Boolean(propertyTypesArray.includes('condo'));
      const duplexType = Boolean(propertyTypesArray.includes('divided'));
      const houseType = Boolean(propertyTypesArray.includes('house'));
      const townhouseType = Boolean(propertyTypesArray.includes('townhouse'));
      const landType = Boolean(propertyTypesArray.includes('land'));
      const anyPropertyType = Boolean(
        apartmentType && condoType && duplexType && houseType && townhouseType && landType,
      );

      if (anyPropertyType) {
        return {
          any: anyPropertyType,
          apartment: false,
          condo: false,
          duplex: false,
          house: false,
          townhouse: false,
        };
      }

      return {
        any: false,
        apartment: apartmentType,
        condo: condoType,
        duplex: duplexType,
        house: houseType,
        townhouse: townhouseType,
      };
    },
    createdWithin: (str: string | null = null): CreatedWithinFilter => {
      if (!str) {
        return DEFAULT.createdWithin;
      }

      // FILTER_ENUM.MAX_CREATED is a map of numbers.
      // Cast str here to a number and check if it's in the map

      const createdWithinTheHour = str === FILTER_ENUM.MAX_CREATED['1'];
      const createdWithinADay = str === FILTER_ENUM.MAX_CREATED['24'];
      const createdWithinAWeek = str === FILTER_ENUM.MAX_CREATED['168'];
      const createdWithinTheMonth = str === FILTER_ENUM.MAX_CREATED['720'];

      return {
        any: false,
        hour: createdWithinTheHour,
        day: createdWithinADay,
        week: createdWithinAWeek,
        month: createdWithinTheMonth,
      };
    },
    rentalTypes: (str: string | null = null): RentalTypesFilter => {
      if (!str) {
        return DEFAULT.rentalTypes;
      }

      const rentalTypesArray = str.split(',');

      const rentalType = Boolean(rentalTypesArray.includes('rental'));
      const roomType = Boolean(rentalTypesArray.includes('room'));
      const subletType = Boolean(rentalTypesArray.includes('sublet'));
      const corporateType = Boolean(rentalTypesArray.includes('corporate'));

      return {
        rental: rentalType,
        room: roomType,
        sublet: subletType,
        corporate: corporateType,
      };
    },
    restrictions: ({
      income = DEFAULT.restrictions.incomeRestricted,
      senior = DEFAULT.restrictions.seniorHousing,
      student = DEFAULT.restrictions.studentHousing,
      military = DEFAULT.restrictions.militaryHousing,
    }: {
      senior?: boolean | null;
      student?: boolean | null;
      military?: boolean | null;
      income?: boolean | null;
    }): RestrictionsFilter => {
      const isValid =
        typeof income === 'boolean' &&
        typeof senior === 'boolean' &&
        typeof student === 'boolean' &&
        typeof military === 'boolean';

      const allFalse = income === false && senior === false && student === false && military === false;

      if (allFalse) {
        return {
          incomeRestricted: false,
          seniorHousing: false,
          studentHousing: false,
          militaryHousing: false,
          none: true,
        };
      }

      if (isValid) {
        return {
          incomeRestricted: income,
          seniorHousing: senior,
          studentHousing: student,
          militaryHousing: military,
          none: false,
        };
      }

      return DEFAULT.restrictions;
    },
    additionalOpts: ({
      requiresPhotos = DEFAULT.additionalOpts.requiresPhotos,
      requiresPrice = DEFAULT.additionalOpts.requiresPrice,
      forRentByOwner = DEFAULT.additionalOpts.forRentByOwner,
      acceptsSection8 = DEFAULT.additionalOpts.acceptsSection8,
      hasOffers = DEFAULT.additionalOpts.hasOffers,
      acceptingApplications = DEFAULT.additionalOpts.acceptingApplications,
    }: {
      requiresPhotos?: number | boolean | null;
      requiresPrice?: boolean | null;
      forRentByOwner?: boolean | null;
      acceptsSection8?: boolean | null;
      hasOffers?: boolean | null;
      acceptingApplications?: boolean | null;
    }): AdditionalOptions => {
      const isValidForRentByOwnerValue = typeof forRentByOwner === 'boolean';
      const isValidSection8Value = typeof acceptsSection8 === 'boolean';
      const isValidOffersValue = typeof hasOffers === 'boolean';
      const isValidAcceptingAppsValue = typeof acceptingApplications === 'boolean';

      return {
        requiresPhotos: requiresPhotos === 1 ? true : DEFAULT.additionalOpts.requiresPhotos,
        requiresPrice: requiresPrice === false ? true : DEFAULT.additionalOpts.requiresPrice, // False === checkbox checked in UI
        forRentByOwner: isValidForRentByOwnerValue ? forRentByOwner : DEFAULT.additionalOpts.forRentByOwner,
        acceptsSection8: isValidSection8Value ? acceptsSection8 : DEFAULT.additionalOpts.acceptsSection8,
        hasOffers: isValidOffersValue ? hasOffers : DEFAULT.additionalOpts.hasOffers,
        acceptingApplications: isValidAcceptingAppsValue
          ? acceptingApplications
          : DEFAULT.additionalOpts.acceptingApplications,
      };
    },
    nonUserFacing: ({
      feeds = DEFAULT.NON_USER_FACING.feeds,
      dupeGrouping = DEFAULT.NON_USER_FACING.dupeGrouping,
      visible = DEFAULT.NON_USER_FACING.visible,
    }: {
      feeds?: string | null;
      dupeGrouping?: string | null;
      visible?: string;
    }): NonUserFacing => {
      return {
        feeds,
        dupeGrouping,
        visible,
      };
    },
  },
};

interface FilterParams {
  orderBy?: string;
  searchSlug?: string;
  searchTitle?: string;
  lowPrice?: number | null;
  highPrice?: number | null;
  bedrooms?: string | null;
  bathrooms?: string | null;

  commuteMode?: string | null;
  commuteTimeMode?: string | null | undefined;
  commuteTime?: string | null | undefined;
  commuteLats?: number | null;
  commuteLons?: number | null;

  pets?: string;

  startOfAvailabilityDate?: string | null;
  endOfAvailabilityDate?: string | null;
  hideUnknownAvailabilityDate?: boolean | null;

  laundry?: string;
  amenities?: string;
  furnished?: string;

  propertyTypes?: string | null;

  minSqft?: number | null;
  maxSqft?: number | null;

  maxCreated?: string | null;

  listingTypes?: string | null;

  seniorHousing?: boolean | null;
  studentHousing?: boolean | null;
  militaryHousing?: boolean | null;
  incomeRestricted?: boolean | null;

  keywords?: string;

  minPhotos?: number | null;
  includeVaguePricing?: boolean | null;
  isListedByOwner?: boolean | null;
  acceptsSection8?: boolean | null;
  hasSpecialOffers?: boolean | null;
  isAcceptingRentalApplications?: boolean | null;

  feeds?: string | null;
  dupeGrouping?: string | null;
  visible?: string;
}

class Filter {
  orderBy: string;
  search: SearchFilter;
  price: PriceRangeFilter;
  bedrooms: BedroomFilter;
  bathrooms: BathroomFilter;
  commute: CommuteFilter;
  pets: PetsFilter;
  availability: AvailabilityFilter;
  laundry: LaundryFilter;
  amenities: AmenitiesFilter;
  furnished: boolean;
  propertyTypes: PropertyTypesFilter;
  sqft: SqftFilter;
  createdWithin: CreatedWithinFilter;
  rentalTypes: RentalTypesFilter;
  restrictions: RestrictionsFilter;
  keywords: string;
  additionalOpts: AdditionalOptions;
  NON_USER_FACING: NonUserFacing;

  constructor(res: FilterParams = {}) {
    try {
      this.orderBy = MODEL.SPECIFIC.orderBy(res.orderBy);
      this.search = MODEL.SPECIFIC.search({
        slug: res.searchSlug || DEFAULT.search.slug,
        title: res.searchTitle || DEFAULT.search.title,
      });
      this.price = MODEL.SPECIFIC.price({
        min: res.lowPrice !== undefined ? res.lowPrice : DEFAULT.price.min,
        max: res.highPrice !== undefined ? res.highPrice : DEFAULT.price.max,
      });
      this.bedrooms = MODEL.SPECIFIC.bedrooms(res.bedrooms);
      this.bathrooms = MODEL.SPECIFIC.bathrooms(res.bathrooms);
      this.commute = MODEL.SPECIFIC.commute({
        timeMode: res.commuteTimeMode as CommuteModeType | null,
        time: res.commuteTime,
        mode: res.commuteMode as CommuteModeType | null,
        lat: res.commuteLats,
        lon: res.commuteLons,
      });
      this.pets = MODEL.GENERAL.standardTruthy(res.pets || '', 'pets')!;
      this.availability = MODEL.SPECIFIC.availability({
        start: res.startOfAvailabilityDate,
        end: res.endOfAvailabilityDate,
        hideUnknownAvailabilityDate: res.hideUnknownAvailabilityDate,
      });
      this.laundry = MODEL.GENERAL.standardTruthy(res.laundry || '', 'laundry')!;
      this.amenities = MODEL.GENERAL.standardTruthy(res.amenities || '', 'amenities')!;
      this.furnished = MODEL.SPECIFIC.furnished(res.furnished);
      this.propertyTypes = MODEL.SPECIFIC.propertyTypes(res.propertyTypes);
      this.sqft = MODEL.SPECIFIC.sqft({ min: res.minSqft, max: res.maxSqft });
      this.createdWithin = MODEL.SPECIFIC.createdWithin(res.maxCreated);
      this.rentalTypes = MODEL.SPECIFIC.rentalTypes(res.listingTypes);
      this.restrictions = MODEL.SPECIFIC.restrictions({
        senior: res.seniorHousing,
        student: res.studentHousing,
        military: res.militaryHousing,
        income: res.incomeRestricted,
      });
      this.keywords = res.keywords || DEFAULT.keywords;
      this.additionalOpts = MODEL.SPECIFIC.additionalOpts({
        requiresPhotos: res.minPhotos,
        requiresPrice: res.includeVaguePricing,
        forRentByOwner: res.isListedByOwner,
        acceptsSection8: res.acceptsSection8,
        hasOffers: res.hasSpecialOffers,
        acceptingApplications: res.isAcceptingRentalApplications,
      });
      this.NON_USER_FACING = MODEL.SPECIFIC.nonUserFacing({
        feeds: res.feeds,
        dupeGrouping: res.dupeGrouping,
        visible: res.visible,
      });
    } catch (error) {
      throw Error(error);
    }
  }
}

export default Filter;
