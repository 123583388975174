/* eslint-enable */
// Lodash
import round from 'lodash/round';
import isNil from 'lodash/isNil';

// Models
import MapDataQueryObj from 'app/shared/models/MapDataQueryObj';

// Utils
import { sanitizingUtils_sanitizeUndefinedAndNullValues } from 'app/shared/utils/sanitizingUtils';

// Types
import type {
  QueryToJavaInput,
  QueryToJavaOutput,
  ReduxAndSearchStateToJavaInput,
  ReduxAndSearchStateToJavaOutput,
  ReduxAndSearchStateToQueryInput,
  ReduxAndSearchStateToQueryOutput,
} from 'app/types/adapter.type';
import type { CommuteModeTypesReduxToJava } from 'app/types/filterConstants.type';
import type {
  AmenitiesFilter,
  BathroomFilter,
  BedroomFilter,
  CreatedWithinFilter,
  LaundryFilter,
  PetsFilter,
  PropertyTypesFilter,
  RentalTypesFilter,
} from 'app/types/filter.type';

// Allowed Filter Constants
import {
  BATHROOMS,
  BEDROOMS,
  COMMUTE_MODE_TYPES_REDUX_TO_JAVA,
  FILTER_ENUM,
  MAX_CREATED_TYPES_REDUX_TO_JAVA,
  PROPERTY_TYPES,
} from 'app/shared/constants/FilterConstants';

// String Constants
import StringConstants from 'app/shared/constants/StringConstants';

/**
 * adapt_queryToJava
 * Adapts query object containing hyphen delimited params into a comma delimited object
 * ready for searchSlugToFilter API consumption.
 *
 * @param {QueryToJavaInput} _query - Query object containing hyphen delimited params
 * @returns {QueryToJavaOutput} Object with comma delimited values and proper types for Java API
 *
 * @example
 * // Input query with hyphen delimited values
 * {
 *   amenities: 'parking-swimmingPool',
 *   baths: '2-8plus',
 *   beds: '2-8plus',
 *   price: '1000-2000',
 *   sqft: '1000-2000'
 * }
 *
 * // Output with comma delimited values
 * {
 *   amenities: 'parking,swimmingPool',
 *   bathrooms: '2,3,4,5,6,7,8plus',
 *   bedrooms: '2,3,4,5,6,7,8plus',
 *   lowPrice: 1000,
 *   highPrice: 2000,
 *   minSqft: 1000,
 *   maxSqft: 2000
 * }
 *
 * @description
 * This function handles various types of conversions:
 * - Hyphen delimited strings to comma delimited strings
 * - String numbers to actual numbers
 * - Boolean strings to actual booleans
 * - Special handling for bedrooms, bathrooms, and property types
 * - Proper null/undefined handling
 */
const adapt_queryToJava = (_query: QueryToJavaInput): QueryToJavaOutput => {
  const ADAPT = {
    GENERAL: {
      boolQuery: (val: boolean | string | undefined): boolean | undefined => {
        if (isNil(val)) {
          return undefined;
        }

        if (typeof val === 'boolean') {
          return val === true ? true : undefined;
        }

        return val === 'true' ? true : undefined;
      },
      truthyChainQuery: (val: string | undefined, ENUM: Record<string, string>): string | undefined => {
        if (isNil(val)) {
          return undefined;
        }

        return val
          .split('-')
          .sort()
          .filter((v) => {
            const isValid = ENUM[v];
            return !isNil(isValid);
          })
          .join(',');
      },
      truthyQuery: (val: string | undefined, ENUM: Record<string, string>): string | undefined => {
        if (isNil(val)) {
          return undefined;
        }

        const enumValue = ENUM[val];
        if (typeof enumValue === 'string') {
          return enumValue;
        }
        return undefined;
      },
      truthyMixedQuery: (val: string | undefined, ENUM: Record<string, string | number>): string | undefined => {
        if (isNil(val)) {
          return undefined;
        }

        const enumValue = ENUM[val];
        if (typeof enumValue === 'string' || typeof enumValue === 'number') {
          return String(enumValue);
        }
        return undefined;
      },
      truthyNumberQuery: (val: string | undefined, ENUM: Record<string, number>): number | undefined => {
        if (isNil(val)) {
          return undefined;
        }

        const enumValue = ENUM[val];
        if (typeof enumValue === 'number') {
          return enumValue;
        }
        return undefined;
      },
    },
    SPECIFIC: {
      bedroomsQuery: (val: string | undefined): string | undefined | boolean => {
        // If no arg is passed in
        if (isNil(val)) {
          return;
        }

        const [min = 0, max = null] = val.split('-');
        const isExact = max === null;

        const bedroomsExact: Record<string, string | false> = {
          0: Number(min) === 0 && '0',
          1: Number(min) === 1 && '1',
          2: Number(min) === 2 && '2',
          3: Number(min) === 3 && '3',
          4: Number(min) === 4 && '4',
        };
        const bedroomsRange: Record<string, string | false> = {
          0: Number(min) === 0 && BEDROOMS.ANY,
          1: Number(min) === 1 && BEDROOMS.ONE_BEDROOM_AND_UP,
          2: Number(min) === 2 && BEDROOMS.TWO_BEDROOMS_AND_UP,
          3: Number(min) === 3 && BEDROOMS.THREE_BEDROOMS_AND_UP,
          4: Number(min) === 4 && BEDROOMS.FOUR_BEDROOMS_AND_UP,
        };

        const res = isExact ? bedroomsExact[min] : bedroomsRange[min];

        // If arg is passed in
        if (!isNil(res)) {
          return res;
        }

        // If erroneous args passed in
        return BEDROOMS.ANY;
      },
      bathroomsQuery: (val: string | undefined): string | undefined | boolean => {
        // If no arg is passed in
        if (isNil(val)) {
          return;
        }

        const [min = 0] = val.split('-');
        const bathroomsRange: Record<string, string | false> = {
          0: Number(min) === 0 && BATHROOMS.ANY,
          1: Number(min) === 1 && BATHROOMS.ONE_BATHROOM_AND_UP,
          1.5: Number(min) === 1.5 && BATHROOMS.ONE_HALF_BATHROOMS_AND_UP,
          2: Number(min) === 2 && BATHROOMS.TWO_BATHROOMS_AND_UP,
          3: Number(min) === 3 && BATHROOMS.THREE_BATHROOMS_AND_UP,
          4: Number(min) === 4 && BATHROOMS.FOUR_BATHROOMS_AND_UP,
        };
        const res = bathroomsRange[min];

        // If arg is passed in
        if (!isNil(res)) {
          return res;
        }

        // If erroneous args passed in
        return BATHROOMS.ANY;
      },
      minPhotosQuery: (val: string | undefined): number | null | undefined => {
        if (isNil(val)) {
          return;
        }
        return val === '1' ? 1 : null;
      },
      vaguePricingQuery: (val: boolean | string | undefined): boolean | null | undefined => {
        if (isNil(val)) {
          return;
        }

        if (typeof val === 'boolean') {
          return val === false ? false : null;
        }

        return val === 'false' ? false : null;
      },
      latLonQuery: (val: string | undefined): number | null | undefined => {
        if (isNil(val)) {
          return;
        }

        const num = Number(val);

        if (!isNaN(num)) {
          return num;
        }
        return null;
      },
      availabilityQuery: (val: string | undefined): { start: string | null; end: string | null } => {
        if (isNil(val)) {
          return { start: null, end: null };
        }

        const splitDates = val.split('to');

        if (splitDates.length === 2) {
          const [start, end] = splitDates;
          return { start, end };
        }

        return { start: null, end: null };
      },
      priceOrSqftQuery: (val: string | undefined): { min: number | null; max: number | null } => {
        if (isNil(val)) {
          return { min: null, max: null };
        }

        const [rawMin = 0, rawMax = null] = val.split('-');
        const min = round(Number(rawMin));
        const max = rawMax ? round(Number(rawMax)) : null;

        if (isNaN(min) || (max !== null && isNaN(max))) {
          return { min: null, max: null };
        }

        if (!isNaN(min)) {
          return { min, max };
        }

        return { min, max };
      },
    },
  };

  const javaParams = {
    orderBy: ADAPT.GENERAL.truthyQuery(_query.orderBy, FILTER_ENUM.ORDER_BY),
    lowPrice: ADAPT.SPECIFIC.priceOrSqftQuery(_query.price).min,
    highPrice: ADAPT.SPECIFIC.priceOrSqftQuery(_query.price).max,
    bedrooms: ADAPT.SPECIFIC.bedroomsQuery(_query.beds),
    bathrooms: ADAPT.SPECIFIC.bathroomsQuery(_query.baths),
    commuteTimeMode: ADAPT.GENERAL.truthyQuery(_query.commuteTimeMode, FILTER_ENUM.COMMUTE),
    commuteTime: ADAPT.GENERAL.truthyQuery(_query.commuteTime, FILTER_ENUM.COMMUTE),
    commuteMode: ADAPT.GENERAL.truthyQuery(_query.commuteMode, FILTER_ENUM.COMMUTE),
    commuteLats: ADAPT.SPECIFIC.latLonQuery(_query.commuteLats),
    commuteLons: ADAPT.SPECIFIC.latLonQuery(_query.commuteLons),
    pets: ADAPT.GENERAL.truthyChainQuery(_query.pets, FILTER_ENUM.PETS),
    startOfAvailabilityDate: ADAPT.SPECIFIC.availabilityQuery(_query.avail).start,
    endOfAvailabilityDate: ADAPT.SPECIFIC.availabilityQuery(_query.avail).end,
    hideUnknownAvailabilityDate: ADAPT.GENERAL.boolQuery(_query.hideUnkAvail),
    laundry: ADAPT.GENERAL.truthyChainQuery(_query.laundry, FILTER_ENUM.LAUNDRY),
    amenities: ADAPT.GENERAL.truthyChainQuery(_query.amenities, FILTER_ENUM.AMENITIES),
    furnished: ADAPT.GENERAL.boolQuery(_query.furnished),
    propertyTypes: ADAPT.GENERAL.truthyChainQuery(_query.propertyTypes, FILTER_ENUM.PROPERTY_TYPES),
    minSqft: ADAPT.SPECIFIC.priceOrSqftQuery(_query.sqft).min,
    maxSqft: ADAPT.SPECIFIC.priceOrSqftQuery(_query.sqft).max,
    maxCreated: ADAPT.GENERAL.truthyQuery(_query.maxCreated, FILTER_ENUM.MAX_CREATED),
    listingTypes: ADAPT.GENERAL.truthyChainQuery(_query.listingTypes, FILTER_ENUM.LISTING_TYPES),
    incomeRestricted: _query.incomeRestricted,
    seniorHousing: _query.seniorHousing,
    studentHousing: _query.studentHousing,
    militaryHousing: _query.militaryHousing,
    keywords: _query.keywords,
    minPhotos: ADAPT.SPECIFIC.minPhotosQuery(_query.photos),
    includeVaguePricing: ADAPT.SPECIFIC.vaguePricingQuery(_query.includeVaguePricing),
    isListedByOwner: ADAPT.GENERAL.boolQuery(_query.isListedByOwner),
    acceptsSection8: ADAPT.GENERAL.boolQuery(_query.acceptsSection8),
    hasSpecialOffers: ADAPT.GENERAL.boolQuery(_query.promo),
    isAcceptingRentalApplications: ADAPT.GENERAL.boolQuery(_query.applicationsOk),

    // Non-user facing
    feeds: _query.feeds,
    dupeGrouping: _query.dupeGrouping,
    visible: _query.visible,
  };
  const finalParams = sanitizingUtils_sanitizeUndefinedAndNullValues(javaParams) as QueryToJavaOutput;

  return finalParams;
};

/**
 * adapt_reduxToJava
 * Adapts Redux filter state and area data into a format suitable for ByCoordsV2 API consumption.
 *
 * @param {ReduxAndSearchStateToJavaInput} params - Object containing Redux filter state, area data, and pagination parameters
 * @returns {ReduxAndSearchStateToJavaOutput} Object with properly formatted values for Java API
 *
 * @example
 * // Input with Redux filter state and area data
 * {
 *   filter: {
 *     price: { min: 1000, max: 2000 },
 *     bedrooms: { twoOrMore: true, isExact: false },
 *     bathrooms: { twoOrMore: true },
 *     commute: { commuteMode: 'Driving', commuteTime: '30' }
 *   },
 *   area: {
 *     id: '123',
 *     type: 'city',
 *     lat: 37.7749,
 *     lon: -122.4194,
 *     minLat: 37.7,
 *     maxLat: 37.8,
 *     minLon: -122.5,
 *     maxLon: -122.3
 *   },
 *   offset: 0,
 *   limit: 20
 * }
 *
 * // Output with formatted values
 * {
 *   lowPrice: 1000,
 *   highPrice: 2000,
 *   bedrooms: '2,3,4,5,6,7,8plus',
 *   bathrooms: '2,3,4,5,6,7,8plus',
 *   commuteMode: 'DRIVING',
 *   commuteTime: '30',
 *   lat: 37.7749,
 *   lon: -122.4194,
 *   minLat: 37.7,
 *   maxLat: 37.8,
 *   minLon: -122.5,
 *   maxLon: -122.3,
 *   offset: 0,
 *   limit: 20
 * }
 *
 * @description
 * This function handles various types of conversions:
 * - Converts Redux filter state to Java API format
 * - Handles special cases for bedrooms, bathrooms, and property types
 * - Processes area data for geographical search
 * - Manages pagination parameters
 * - Handles commute mode and time conversions
 * - Properly formats boolean values and nulls
 */
const adapt_reduxToJava = ({
  filter: _filter,
  area: _area,
  offset: _offset = 0,
  limit: _limit = 20,
  channels: _channels,
  components: _components,
  mapData: _mapData,
}: ReduxAndSearchStateToJavaInput): ReduxAndSearchStateToJavaOutput => {
  const ADAPT = {
    GENERAL: {
      truthyReduxField: (val: Record<string, boolean>): string => {
        const keys = Object.keys(val);
        const active = keys.filter((opt) => val[opt]);
        return active.join(',');
      },
    },
    SPECIFIC: {
      bedroomsReduxField: (val: BedroomFilter): string => {
        const keys = Object.keys(val);
        const active = keys.filter((opt) => val[opt as keyof BedroomFilter]);
        const opt = active[0] as keyof BedroomFilter;
        const isExact = active[1] === 'isExact';
        const res = {
          anyOrStudio: isExact ? '0' : BEDROOMS.ANY, // Maps to Any or Studio
          oneOrMore: isExact ? '1' : BEDROOMS.ONE_BEDROOM_AND_UP, // Maps to 1 or 1+
          twoOrMore: isExact ? '2' : BEDROOMS.TWO_BEDROOMS_AND_UP, // Maps to 2 or 2+
          threeOrMore: isExact ? '3' : BEDROOMS.THREE_BEDROOMS_AND_UP, // Maps to 3 or 3+
          fourOrMore: isExact ? '4' : BEDROOMS.FOUR_BEDROOMS_AND_UP, // Maps to 4 or 4+
        } as const;

        return res[opt as keyof typeof res];
      },
      bathroomsReduxField: (val: BathroomFilter): string => {
        const keys = Object.keys(val);
        const active = keys.filter((opt) => val[opt as keyof BathroomFilter]);
        const opt = active[0] as keyof BathroomFilter;
        const res: Record<keyof BathroomFilter, string> = {
          any: BATHROOMS.ANY, // Maps to Any
          oneOrMore: BATHROOMS.ONE_BATHROOM_AND_UP, // Maps to 1+
          oneHalfOrMore: BATHROOMS.ONE_HALF_BATHROOMS_AND_UP, // Maps to 1.5+
          twoOrMore: BATHROOMS.TWO_BATHROOMS_AND_UP, // Maps to 2+
          threeOrMore: BATHROOMS.THREE_BATHROOMS_AND_UP, // Maps to 3+
          fourOrMore: BATHROOMS.FOUR_BATHROOMS_AND_UP, // Maps to 4+
        };

        return res[opt];
      },
      commuteReduxField: (val: keyof CommuteModeTypesReduxToJava | null | undefined): string | null => {
        if (!val) return null;
        // 'Driver' => 'DRIVER' or 'Off peak' => 'offPeak
        return COMMUTE_MODE_TYPES_REDUX_TO_JAVA[val];
      },
      propertyTypesReduxField: (val: PropertyTypesFilter): string => {
        const keys = Object.keys(val);
        const active = keys.filter((opt) => val[opt as keyof PropertyTypesFilter]);
        const isAnyOptionSelected = active[0] === 'any';

        if (isAnyOptionSelected) {
          return PROPERTY_TYPES.ANY;
        }

        const indexOfApartmentType = active.indexOf('apartment');
        const indexOfDuplexType = active.indexOf('duplex');

        if (indexOfApartmentType > -1) {
          active[indexOfApartmentType] = PROPERTY_TYPES.APARTMENT;
        }

        if (indexOfDuplexType > -1) {
          active[indexOfDuplexType] = PROPERTY_TYPES.DUPLEX;
        }

        return active.join(',');
      },
      createdWithinReduxField: (val: CreatedWithinFilter): string | null => {
        const keys = Object.keys(val);
        const active = keys.filter((opt) => val[opt as keyof CreatedWithinFilter])[0] as keyof CreatedWithinFilter;

        const res: Record<keyof CreatedWithinFilter, string | null> = {
          any: null,
          hour: '1',
          day: '24',
          week: '168',
          month: '720',
        };

        return res[active];
      },
      listingTypesReduxField: (val: RentalTypesFilter): string => {
        const keys = Object.keys(val);
        const active = keys.filter((opt) => val[opt as keyof RentalTypesFilter]);

        return active.join(',');
      },
    },
  };

  const queryObj = {
    // Filter based params
    orderBy: _filter.orderBy,
    lowPrice: _filter.price.min,
    highPrice: _filter.price.max,
    bedrooms: ADAPT.SPECIFIC.bedroomsReduxField(_filter.bedrooms),
    bathrooms: ADAPT.SPECIFIC.bathroomsReduxField(_filter.bathrooms),
    commuteTimeMode: ADAPT.SPECIFIC.commuteReduxField(
      _filter.commute.commuteTimeMode as keyof CommuteModeTypesReduxToJava,
    ),
    commuteTime: _filter.commute.commuteTime,
    commuteMode: ADAPT.SPECIFIC.commuteReduxField(_filter.commute.commuteMode as keyof CommuteModeTypesReduxToJava),
    commuteLats: _filter.commute.commuteLats,
    commuteLons: _filter.commute.commuteLons,
    pets: ADAPT.GENERAL.truthyReduxField(_filter.pets as unknown as Record<string, boolean>),
    startOfAvailabilityDate: _filter.availability.start,
    endOfAvailabilityDate: _filter.availability.end,
    hideUnknownAvailabilityDate: _filter.availability.hideUnknownAvailabilityDate,
    laundry: ADAPT.GENERAL.truthyReduxField(_filter.laundry as unknown as Record<string, boolean>),
    amenities: ADAPT.GENERAL.truthyReduxField(_filter.amenities as unknown as Record<string, boolean>),
    furnished: _filter.furnished ? true : null,
    propertyTypes: ADAPT.SPECIFIC.propertyTypesReduxField(_filter.propertyTypes),
    minSqft: _filter.sqft.min === 0 ? null : _filter.sqft.min,
    maxSqft: _filter.sqft.max === 3800 ? null : _filter.sqft.max,
    maxCreated: ADAPT.SPECIFIC.createdWithinReduxField(_filter.createdWithin),
    listingTypes: ADAPT.SPECIFIC.listingTypesReduxField(_filter.rentalTypes),
    incomeRestricted: _filter.restrictions.incomeRestricted,
    seniorHousing: _filter.restrictions.seniorHousing,
    studentHousing: _filter.restrictions.studentHousing,
    militaryHousing: _filter.restrictions.militaryHousing,
    keywords: _filter.keywords,
    minPhotos: _filter.additionalOpts.requiresPhotos ? 1 : null,
    includeVaguePricing: _filter.additionalOpts.requiresPrice ? false : null,
    isListedByOwner: _filter.additionalOpts.forRentByOwner ? true : null,
    acceptsSection8: _filter.additionalOpts.acceptsSection8 ? true : null,
    hasSpecialOffers: _filter.additionalOpts.hasOffers ? true : null,
    isAcceptingRentalApplications: _filter.additionalOpts.acceptingApplications ? true : null,

    // Non-user facing params
    feeds: _filter.NON_USER_FACING.feeds,
    dupeGrouping: _filter.NON_USER_FACING.dupeGrouping,
    visible: _filter.NON_USER_FACING.visible,

    // Area based params
    // HPWEB-5750: School SRP not returning listings.
    // Model index does not return listings for university area IDs.
    // Passing null forces byCoords call to search by lat/long instead.
    areas: _area && _area.type !== 'university' ? _area.id : null,
    lat: _area?.lat ?? '',
    lon: _area?.lon ?? '',
    maxLat: (_area?.maxLat || _mapData?.maxLat) ?? '',
    maxLon: (_area?.maxLon || _mapData?.maxLon) ?? '',
    minLat: (_area?.minLat || _mapData?.minLat) ?? '',
    minLon: (_area?.minLon || _mapData?.minLon) ?? '',

    // Other params
    offset: _offset,
    limit: _limit,
    channels: _channels || '',
    components: _components || 'basic,useritem,quality,model,photos',
  };

  const finalParams = sanitizingUtils_sanitizeUndefinedAndNullValues(queryObj);

  return finalParams;
};

/**
 * adapt_reduxToQuery
 * Adapts Redux filter state into a URL-friendly query string format
 *
 * @param {ReduxAndSearchStateToQueryInput} params - Object containing filter state and map data
 * @param {FilterState} params.filter - Redux filter state containing search criteria
 * @param {Object} [params.mapData] - Map data containing coordinates and zoom level
 * @param {number} params.mapData.minLat - Minimum latitude of map bounds
 * @param {number} params.mapData.minLon - Minimum longitude of map bounds
 * @param {number} params.mapData.maxLat - Maximum latitude of map bounds
 * @param {number} params.mapData.maxLon - Maximum longitude of map bounds
 * @param {boolean} [params.border] - Whether to include area border in search
 * @param {number|string} [params.page] - Page number for pagination
 * @param {AnalyticsQueryParams} [params.analytics] - Analytics experiment parameters
 * @param {string} [params.analytics.HPWEB_CONTROL] - Control group identifier
 * @param {string} [params.analytics.HPWEB_EXP] - Experiment group identifier
 * @returns {Partial<ReduxAndSearchStateToQueryOutput>} Object with URL-friendly query parameters
 *
 * @example
 * // Input with filter state and map data
 * {
 *   filter: {
 *     price: { min: 1000, max: 2000 },
 *     bedrooms: { twoOrMore: true, isExact: false },
 *     bathrooms: { twoOrMore: true },
 *     commute: { commuteMode: 'Driving', commuteTime: '30' }
 *   },
 *   mapData: {
 *     minLat: 37.7749,
 *     minLon: -122.4194,
 *     maxLat: 37.7849,
 *     maxLon: -122.4094
 *   },
 *   border: false,
 *   page: 1,
 *   analytics: {
 *     HPWEB_CONTROL: 'control1',
 *     HPWEB_EXP: 'exp1'
 *   }
 * }
 *
 * // Output with URL-friendly parameters
 * {
 *   price: '1000-2000',
 *   beds: '2-8plus',
 *   baths: '2-8plus',
 *   commuteMode: 'DRIVING',
 *   commuteTime: '30',
 *   minLat: '37.7749',
 *   minLon: '-122.4194',
 *   maxLat: '37.7849',
 *   maxLon: '-122.4094',
 *   border: 'false',
 *   page: '1',
 *   HPWEB_CONTROL: 'control1',
 *   HPWEB_EXP: 'exp1'
 * }
 *
 * @description
 * This function converts Redux filter state into URL-friendly query parameters:
 * - Converts boolean values to strings
 * - Joins array values with hyphens
 * - Handles special cases for bedrooms, bathrooms, and property types
 * - Processes map data for geographical search
 * - Includes analytics experiment parameters
 * - Manages pagination
 * - Properly formats all values for URL use
 */
const adapt_reduxToQuery = ({
  filter: _filter,
  mapData: _mapData,
  border: _border,
  page: _page,
  analytics: _analytics,
}: ReduxAndSearchStateToQueryInput): Partial<ReduxAndSearchStateToQueryOutput> => {
  const queryObj: Partial<ReduxAndSearchStateToQueryOutput> = {};
  _mapData = Object.assign({}, _mapData);

  if (_filter.orderBy && _filter.orderBy !== 'score' && _filter.orderBy !== 'experimentScore') {
    queryObj.orderBy = _filter.orderBy;
  }

  if (_filter.price) {
    if (_filter.price.min && !_filter.price.max) {
      queryObj.price = [_filter.price.min || 0]?.toString();
    } else if (!_filter.price.min && _filter.price.max) {
      queryObj.price = [0, _filter.price.max].join(StringConstants.QUERY_PARAM_DELIMITER)?.toString();
    } else if (_filter.price.min && _filter.price.max) {
      queryObj.price = [_filter.price.min || 0, _filter.price.max]
        .join(StringConstants.QUERY_PARAM_DELIMITER)
        ?.toString();
    }
  }

  if (_filter.bedrooms) {
    const bedrooms = _filter.bedrooms;
    const isExact = bedrooms.isExact;
    const identifiers = Object.keys(bedrooms);
    const active = identifiers.filter((beds) => {
      return bedrooms[beds as keyof BedroomFilter];
    })[0];

    const queryRes = {
      anyOrStudio: isExact ? '0' : null,
      oneOrMore: isExact ? '1' : '1-8plus',
      twoOrMore: isExact ? '2' : '2-8plus',
      threeOrMore: isExact ? '3' : '3-8plus',
      fourOrMore: isExact ? '4' : '4-8plus',
    } as const;

    const bedQuery = queryRes[active as keyof typeof queryRes];

    if (bedQuery !== null) {
      queryObj.beds = bedQuery?.toString();
    }
  }

  if (_filter.bathrooms) {
    const bathrooms = _filter.bathrooms;
    const identifiers = Object.keys(bathrooms);
    const active = identifiers.filter((beds) => {
      return bathrooms[beds as keyof BathroomFilter];
    })[0];

    const queryRes = {
      any: null,
      oneOrMore: '1-8plus',
      oneHalfOrMore: '1.5-8plus',
      twoOrMore: '2-8plus',
      threeOrMore: '3-8plus',
      fourOrMore: '4-8plus',
    } as const;

    const bathQuery = queryRes[active as keyof typeof queryRes];

    if (bathQuery) {
      queryObj.baths = bathQuery?.toString();
    }
  }

  if (_filter.commute) {
    queryObj.commuteLats = _filter.commute.commuteLats?.toString();
    queryObj.commuteLons = _filter.commute.commuteLons?.toString();
    queryObj.commuteMode =
      COMMUTE_MODE_TYPES_REDUX_TO_JAVA[_filter.commute.commuteMode as keyof CommuteModeTypesReduxToJava]?.toString();
    if (_filter.commute.commuteTime) {
      queryObj.commuteTime = _filter.commute.commuteTime?.toString();
    }
    queryObj.commuteTimeMode =
      COMMUTE_MODE_TYPES_REDUX_TO_JAVA[
        _filter.commute.commuteTimeMode as keyof CommuteModeTypesReduxToJava
      ]?.toString();
  }

  if (_filter.pets) {
    const petCategories = Object.keys(_filter.pets);
    const res = petCategories
      .filter((key) => {
        return _filter.pets[key as keyof PetsFilter];
      })
      .join(StringConstants.QUERY_PARAM_DELIMITER);

    queryObj.pets = res?.toString();
  }

  if (_filter.availability) {
    const availability = _filter.availability;

    if (availability.start && availability.end) {
      queryObj.avail = `${availability.start}to${availability.end}`?.toString();
    }

    if (availability.hideUnknownAvailabilityDate) {
      queryObj.hideUnkAvail = availability.hideUnknownAvailabilityDate?.toString();
    }
  }

  if (_filter.propertyTypes) {
    const identifiers = Object.keys(_filter.propertyTypes);
    const active = identifiers.filter((option) => {
      return _filter.propertyTypes[option as keyof PropertyTypesFilter];
    });
    if (!active.includes('any')) {
      const aptIdx = active.indexOf('apartment');
      const dupIdx = active.indexOf('duplex');
      const reMappedApartmentType = PROPERTY_TYPES.APARTMENT.split(',').join(StringConstants.QUERY_PARAM_DELIMITER);
      const reMappedDuplexType = PROPERTY_TYPES.DUPLEX.split(',').join(StringConstants.QUERY_PARAM_DELIMITER);

      if (aptIdx > -1) {
        active.splice(aptIdx, 1, reMappedApartmentType);
      }

      if (dupIdx > -1) {
        active.splice(dupIdx, 1, reMappedDuplexType);
      }

      queryObj.propertyTypes = active.join(StringConstants.QUERY_PARAM_DELIMITER)?.toString();
    }
  }

  if (_filter.laundry) {
    const identifiers = Object.keys(_filter.laundry);
    const active = identifiers.filter((option) => {
      return _filter.laundry[option as keyof LaundryFilter];
    });

    if (active.length > 0) {
      queryObj.laundry = active.join(StringConstants.QUERY_PARAM_DELIMITER)?.toString();
    }
  }

  if (_filter.amenities) {
    const identifiers = Object.keys(_filter.amenities);
    const active = identifiers.filter((option) => {
      return _filter.amenities[option as keyof AmenitiesFilter];
    });

    if (active.length > 0) {
      queryObj.amenities = active.join(StringConstants.QUERY_PARAM_DELIMITER)?.toString();
    }
  }

  if (_filter.furnished) {
    queryObj.furnished = true?.toString();
  }

  if (_filter.sqft) {
    if (_filter.sqft.min && !_filter.sqft.max) {
      queryObj.sqft = _filter.sqft.min?.toString();
    } else if (_filter.sqft.max) {
      queryObj.sqft = [_filter.sqft.min || 0, _filter.sqft.max].join(StringConstants.QUERY_PARAM_DELIMITER)?.toString();
    }
  }

  if (_filter.createdWithin) {
    const identifiers = Object.keys(_filter.createdWithin);
    const active = identifiers.filter((option) => {
      return _filter.createdWithin[option as keyof CreatedWithinFilter];
    })[0];

    if (active !== 'any') {
      queryObj.maxCreated =
        MAX_CREATED_TYPES_REDUX_TO_JAVA[active as keyof typeof MAX_CREATED_TYPES_REDUX_TO_JAVA]?.toString();
    }
  }

  if (_filter.rentalTypes) {
    const identifiers = Object.keys(_filter.rentalTypes);
    const active = identifiers.filter((option) => {
      return _filter.rentalTypes[option as keyof RentalTypesFilter];
    });

    if (active.length < 4) {
      queryObj.listingTypes = active.join(StringConstants.QUERY_PARAM_DELIMITER)?.toString();
    }
  }

  if (_filter.restrictions) {
    const restrictions = _filter.restrictions;

    if (restrictions.incomeRestricted || restrictions.incomeRestricted === false) {
      queryObj.incomeRestricted = restrictions.incomeRestricted?.toString();
    }

    if (restrictions.militaryHousing || restrictions.militaryHousing === false) {
      queryObj.militaryHousing = restrictions.militaryHousing?.toString();
    }

    if (restrictions.seniorHousing || restrictions.seniorHousing === false) {
      queryObj.seniorHousing = restrictions.seniorHousing?.toString();
    }

    if (restrictions.studentHousing || restrictions.studentHousing === false) {
      queryObj.studentHousing = restrictions.studentHousing?.toString();
    }
  }

  if (_filter.keywords) {
    queryObj.keywords = _filter.keywords?.toString();
  }

  if (_filter.additionalOpts) {
    const opts = _filter.additionalOpts;

    if (opts.requiresPhotos) {
      queryObj.photos = (1)?.toString();
    }

    if (opts.requiresPrice) {
      queryObj.includeVaguePricing = false?.toString();
    }

    if (opts.forRentByOwner) {
      queryObj.isListedByOwner = opts.forRentByOwner?.toString();
    }

    if (opts.acceptsSection8) {
      queryObj.acceptsSection8 = opts.acceptsSection8?.toString();
    }

    if (opts.hasOffers) {
      queryObj.promo = opts.hasOffers?.toString();
    }

    if (opts.acceptingApplications) {
      queryObj.applicationsOk = opts.acceptingApplications?.toString();
    }
  }

  if (_page) {
    queryObj.page = _page?.toString();
  }

  const mapDataObj = new MapDataQueryObj(_mapData);

  if (_border === false) {
    mapDataObj.border = false?.toString();
  }

  if (_analytics) {
    if (_analytics.HPWEB_CONTROL) {
      queryObj.HPWEB_CONTROL = _analytics.HPWEB_CONTROL;
    }

    if (_analytics.HPWEB_EXP) {
      queryObj.HPWEB_EXP = _analytics.HPWEB_EXP;
    }
  }

  Object.assign(queryObj, mapDataObj);

  return queryObj;
};

export { adapt_queryToJava, adapt_reduxToJava, adapt_reduxToQuery };
