interface MapDataQueryObjProps {
  lat?: string | number | null;
  lon?: string | number | null;
  maxLat?: string | number | null;
  minLat?: string | number | null;
  maxLon?: string | number | null;
  minLon?: string | number | null;
  zoom?: string | number | null;
  border?: string | boolean | null;
}

class MapDataQueryObj {
  z: string | null;
  lat: string | null;
  lon: string | null;
  maxLat: string | null;
  minLat: string | null;
  maxLon: string | null;
  minLon: string | null;
  border: string | null;

  constructor({
    lat = null,
    lon = null,
    maxLat = null,
    minLat = null,
    maxLon = null,
    minLon = null,
    zoom = null,
    border = null,
  }: MapDataQueryObjProps = {}) {
    // Cast all params to Number type.
    // Handles use case: when passing in
    // values from URL query parameters
    // they will be of type String.
    const numLat = lat !== null ? Number(lat) : null;
    const numLon = lon !== null ? Number(lon) : null;
    const numMaxLat = maxLat !== null ? Number(maxLat) : null;
    const numMinLat = minLat !== null ? Number(minLat) : null;
    const numMaxLon = maxLon !== null ? Number(maxLon) : null;
    const numMinLon = minLon !== null ? Number(minLon) : null;
    const numZoom = zoom !== null ? Number(zoom) : null;

    // NOTE: false values are included in query obj, these have to be null instead of false
    this.z = numZoom !== null ? String(numZoom) : null;
    this.lat = numLat !== null ? String(numLat.toFixed(4)) : null;
    this.lon = numLon !== null ? String(numLon.toFixed(4)) : null;
    this.maxLat = numZoom || numMaxLat === null ? null : String(numMaxLat.toFixed(4));
    this.minLat = numZoom || numMinLat === null ? null : String(numMinLat.toFixed(4));
    this.maxLon = numZoom || numMaxLon === null ? null : String(numMaxLon.toFixed(4));
    this.minLon = numZoom || numMinLon === null ? null : String(numMinLon.toFixed(4));
    this.border = border !== null ? String(border) : null;
  }
}

export default MapDataQueryObj;
